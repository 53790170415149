<template>
  <div class="relative">
    <img
    :src="`/storage/rox/jacknatal.png`" class="z-10 w-full h-auto">
    <div class="absolute inset-0 flex items-center justify-center z-20">
      <span class="text-3xl md:text-5xl lg:text-7xl mt-6 lg:mt-16 p-4 md:p-6 lg:p-8 font-bold text-[#FFFF6B] flex items-center justify-center">{{ numberWithCommas(currentValue) }}</span>
    </div>
  </div>
</template>



<script>
import { ref, onMounted } from 'vue';

export default {
  setup() {
    const initialValue = 10000000; // 10 milhões
    const endValue = 990000000; // 99 milhões
    const duration = 30 * 60 * 1000; // 30 minutos em milissegundos
    const numSteps = 100; // Número de etapas
    const currentValue = ref(initialValue);
    const increment = (endValue - initialValue) / numSteps; // Incremento por etapa

    const numberWithCommas = (value) => {
      return value.toLocaleString('pt-BR', { minimumFractionDigits: 0 });
    };

    const animateNumbers = () => {
      const startTime = Date.now();
      const interval = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        if (elapsedTime >= duration) {
          clearInterval(interval);
          currentValue.value = initialValue;
          setTimeout(animateNumbers, 0); // Reinicia a animação após um pequeno atraso
        } else {
          currentValue.value = initialValue + Math.floor(increment * (elapsedTime / duration));
        }
      }, 500); // Tempo de espera de meio segundo para cada casa decimal
    };

    onMounted(() => {
      animateNumbers(); // Iniciar animação ao montar o componente
    });

    return {
      currentValue,
      numberWithCommas
    };
  }
};
</script>

<style scoped>
/* Estilos personalizados podem ser adicionados aqui */
</style>


